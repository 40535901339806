<template>
    <div class='grid'>

        <DayOffReport :report='report'></DayOffReport>


        <div class='col-12 md:col-6 lg:col-12'>
            
            <div class='p-0 h-full flex flex-column'>

                <div class='grid'>
                    <div class='col-12 lg:col-8'>
                        <h5 class='mb-0 mt-3'><i class="pi pi-check mr-2"></i> İzinli Personel Listesi</h5>
                    </div>
                    <div class='col-12 lg:col-4 text-right'>
                    </div>
                </div>

                <Toolbar class='mb-2'>
                    <template v-slot:start>
                        <div>
                            <Calendar id='selectedMonth' view="month" dateFormat="MM - yy" :showIcon='true' :showButtonBar='true' v-model='selectedDate' @date-select='selectedDateChanged'></Calendar>
                        </div>
                        <div class='ml-3'>
                            <Dropdown :model-value='selectedProject' :options='projectList' option-value='id' option-label='name' @change='projectChange'></Dropdown>
                        </div>
                    </template>

                    <template v-slot:end>
                        <Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
                            <h3>İzinli Personel Listesi</h3>
                            <p class="text-lg">
                                Projelerin personeliniz ile ilgili girmiş olduğu ücretli veya ücretsiz izinlerin aylık olarak toplu listesidir.<br>
                                <br>
                                Maaş hesaplamasında girilen ücretsiz izinler personelin maaşından orantılı olarak düşer. 
                            </p>
                        </Sidebar>

                        <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger'/>
                    </template>

                </Toolbar>

                <DataTable :value='dayOffList' class='p-datatable-gridlines' :rows='6' dataKey='id' :rowHover='true' :paginator='true' :loading='loadingIndicator' responsiveLayout='scroll'>

                    <template #empty>
                        İzinli personel bulunamadı.
                    </template>

                    <template #loading>
                        İzinli personeller yükleniyor. Lütfen bekleyin.
                    </template>

                    <Column header='Profil' bodyClass="text-center" style='width:4rem'>
                        <template #body='{data}'>
                            <img :alt='data.employeePhoto' :src="'http://image.a1catering.com.tr/' + data.employee.employeePhoto" class='border-circle border-0 border-solid cursor-pointer' width='80' height='80' style='vertical-align: middle' @click='edit(data)'/>
                        </template>
                    </Column>

                    <Column field='project.name' header='Proje' style='width:14rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.project.name }}
                        </template>
                    </Column>

                    <Column field='employee.name' header='Ad' style='width:13rem'>
                        <template #body='{data}'>
                                <a @click='edit(data)' class="cursor-pointer">
                                    {{ data.employee.name }}
                                </a>
                        </template>
                    </Column>

                    <Column field='employee.surName' header='Soyad' style='width:13rem'>
                        <template #body='{data}'>
                                <a @click='edit(data)' class="cursor-pointer">
                                    {{ data.employee.surName }}
                                </a>
                        </template>
                    </Column>

                    <Column field='projectEmployee.department' header='Departman' style='width:12rem'>
                    </Column>

                    <Column field='startDate' header='Başlangıç Tarihi' style='width:8rem'>
                        <template #body='slotProps'>
                            <b class="text-green-600">{{ formatDate(slotProps.data.startDate) }}</b>
                        </template>
                    </Column>

                    <Column field='endDate' header='Bitiş Tarihi' style='width:8rem'>
                        <template #body='slotProps'>
                            <b class="text-red-600">{{ formatDate(slotProps.data.endDate) }}</b>
                        </template>
                    </Column>

                    <Column field='dayCount' header='Gün Sayısı' bodyClass='text-center' style='width:6rem'>
                    </Column>

                    <Column field='totalPrice' header='Kesilen Ücret' style='width:7rem'>
                        <template #body='slotProps'>
                            <b>{{ formatPrice(slotProps.data.totalPrice) }} &#8378;</b>
                        </template>
                    </Column>          

                    <Column field='isPriceCut' header='Ücret Kesildi Mi?' style='width:7rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.isPriceCut == true ? "Evet" : "Hayır"}}
                        </template>
                    </Column>

                    <Column field='type' header='İzin Tipi' style='width:10rem'>
                    </Column>

                    <Column field='comment' header='Açıklama'>
                    </Column>

                    <Column header='Görüntüle' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-search' class='p-button-rounded p-button-primary mr-2'
                                    @click='edit(slotProps.data)' />
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>


</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import store from '@/store';
import EmployeeDayOffService from '../../services/employeeDayOffService';
import DayOffReport from '../../components/shared/DayOffReport';
import { checkUserRole, getProjects } from '../common/commonConstantFunctions';

export default {
    components: { DayOffReport },
    data() {
        return {
            visibleLeft:false,
            selectedProject:-1,
            projectList:[],
            selectedDate:null,
            dayOffList: [],
            filters: null,
            loadingIndicator: true,
            idFrozen: false,
            products: null,
            expandedRows: [],
            report: {
                totalPersonCount:0,
                totalPriceCut:0,
                totalDayCount:0,
                totalItemCount:0
            }
        };
    },
    _employeeDayOffService: null,
    created() {
        checkUserRole(this, "Admin");
        this.projectList = getProjects();
        this.selectedDate = moment().toDate();
        this.projectId = store.getters.getActiveProject.id;
        this._employeeDayOffService = new EmployeeDayOffService();
        this.initFilters();
        this.loadingIndicator = true;
    },
    async mounted() {
        await this.getEmployeeDayOffList();
    },
    methods: {
        async selectedDateChanged() {
            await this.getEmployeeDayOffList();
        },
        async projectChange(event) {
            this.selectedProject = event.value;
            await this.getEmployeeDayOffList();
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        expandAll() {
            this.expandedRows = this.products.filter(p => p.id);
        },
        collapseAll() {
            this.expandedRows = null;
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        edit(item) {
            this.$router.push({ name: 'dayOffProjectEmployee', params: { employeeId: item.employeeId, projectEmployeeId: item.projectEmployeeId } });
        },
        async getEmployeeDayOffList() {
            this.loadingIndicator = true;
            let startDate = moment(this.selectedDate).startOf('month').toDate();
            let endDate = moment(this.selectedDate).endOf('month').toDate();
            let dayOffResponse = await this._employeeDayOffService.getProjectDayOffsForAccounting(this.selectedProject, startDate, endDate);
            if (dayOffResponse.isSuccess) {
                this.dayOffList = dayOffResponse.data;
                await this.getEmployeeDayOffReport();
            }
            this.loadingIndicator = false;
        },

        async getEmployeeDayOffReport() {
            this.loadingIndicator = true;
            let startDate = moment(this.selectedDate).startOf('month').toDate();
            let endDate = moment(this.selectedDate).endOf('month').toDate();
            let dayOffResponse = await this._employeeDayOffService.getProjectDayOffReportForAccounting(this.selectedProject, startDate, endDate);

            if (dayOffResponse.isSuccess) {
                this.report = dayOffResponse.data;
            }
            console.log(this.report);
            this.loadingIndicator = false;
        },
    },
};
</script>