import { deleteDataById, getData, getPaginatedData, insertData, patchData } from './commonService';

export default class EmployeeDayOffService {


    #defaultUrl = '/api/EmployeeDayOff';
    #defaultResourceName = 'İzin';

    async insertEmployeeDayOff(dataItem) {
        const createData = {
            "projectId": dataItem.projectId,
            "employeeId": dataItem.employeeId,
            "startDate": dataItem.startDate,
            "endDate": dataItem.endDate,
            "dayCount": dataItem.dayCount,
            "isActive": true,
            "type": dataItem.type,
            "isPriceCut": dataItem.isPriceCut,
            "comment": dataItem.comment,
            "totalPrice": dataItem.totalPrice,
            "projectEmployeeId": dataItem.projectEmployeeId,
            "managerUserId": dataItem.managerUserId
        }


        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async updateDayOff(employeeId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "startDate", "value": dataItem.startDate},
            {"op": "replace", "path": "endDate", "value": dataItem.endDate},
            {"op": "replace", "path": "dayCount", "value": dataItem.dayCount},
            {"op": "replace", "path": "isActive", "value": dataItem.isActive},
            {"op": "replace", "path": "type", "value": dataItem.type},
            {"op": "replace", "path": "isPriceCut", "value": dataItem.isPriceCut},
            {"op": "replace", "path": "comment", "value": dataItem.comment}
        ];
        return await patchData(this.#defaultUrl, employeeId, patchDocument, this.#defaultResourceName);

    }

    async deleteEmployeeDayOff(id) {
        return await deleteDataById(this.#defaultUrl, id, this.#defaultResourceName);
    }

    async getEmployeeDayOffs(projectEmployeeId, startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectEmployeeId=' + projectEmployeeId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectDayOffsForAccounting(projectId, startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectDayOffReportForAccounting(projectId, startDate, endDate) {
        return await getData(this.#defaultUrl + '/report?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectDayOffs(projectId, startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectDayOffReport(projectId, startDate, endDate) {
        return await getData(this.#defaultUrl + '/report?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async checkDayOffDates(projectEmployeeId, startDate, endDate) {
        let response = await getData(this.#defaultUrl + '/check?projectEmployeeId=' + projectEmployeeId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() );
        return response.data;
    }

    async getDayCount(startDate, endDate, type) {
        let response = await getData(this.#defaultUrl + '/calculateDayOffDayCount?type='+ type +'&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() );
        return response.data;
    }
}